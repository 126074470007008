<template>
  <navbar />
  <page-title title="Users" />
  <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
    <div class="mb-6 flex justify-between items-center">
      <div class="flex items-center w-full max-w-xs mr-4">
        <div class="flex w-full bg-white shadow rounded">
          <input
            v-on:keyup="search"
            v-model="keyword"
            autocomplete="off"
            type="text"
            name="search"
            placeholder="Search…"
            class="relative w-full px-6 py-2 rounded border placeholder-gray-500 text-gray-900 focus:outline-none focus:border-white focus:ring focus:z-10 sm:text-sm"
          />
        </div>
        <button
          @click="reset"
          type="button"
          class="ml-3 text-sm text-gray-500 hover:text-gray-700 focus:text-indigo-500"
        >
          Reset
        </button>
      </div>
      <router-link
        to="/users/create"
        v-if="isOwner"
        class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        ><span>Create</span>
        <span class="hidden md:inline ml-1">User</span></router-link
      >
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto">
        <div class="py-2 align-middle inline-block min-w-full">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    v-if="isOwner"
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Access Role
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Position
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr
                  v-for="user in users.data"
                  :key="user.id"
                  class="hover:bg-gray-50 cursor-pointer"
                  @click="editUser(user.id)"
                >
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="flex items-center">
                      <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{ user.name }}
                        </div>
                        <div class="text-sm text-gray-500">
                          {{ user.email }}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap" v-if="isOwner">
                    <div class="text-sm text-gray-900">
                      {{ user.role }}
                    </div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-sm text-gray-900">
                      <span v-if="user.owner">Owner</span>
                      <span v-else>Employee</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-if="users.data">
      <pagination
        :links="users.meta.links"
        :active-page="this.$route.name"
        :search-keyword="this.$route.query.search"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/Navbar";
import PageTitle from "@/components/PageTitle";
import Pagination from "@/components/Pagination";
import Cookie from "js-cookie";

export default {
  components: {
    Navbar,
    PageTitle,
    Pagination,
  },
  data() {
    return {
      keyword: "",
      timer: null,
      isOwner:
        Cookie.get("adpos_user_owner") == 1 &&
        Cookie.get("adpos_user_role") == "owner",
    };
  },
  mounted() {
    this.getUsers();
  },
  computed: mapState("users", {
    users: (state) => state.users,
  }),
  methods: {
    getUsers() {
      this.$store.dispatch("users/getUsers", {
        page: this.$route.query.page,
        search: this.$route.query.search,
      });
    },
    search() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: 1,
          search: this.keyword,
        }),
      });

      // Search after user stop typing
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.$store.dispatch("users/getUsers", {
          page: 1,
          search: this.keyword,
        });
      }, 2000);
    },
    reset() {
      this.keyword = "";
      this.$store.dispatch("users/getUsers", { page: 1, search: "" });
      this.$router.push({ name: "Users" });
    },
    editUser(id) {
      this.$router.push({ name: "EditUser", params: { id: id } });
    },
  },
  watch: {
    $route(to, from) {
      // Watch only the ?page query string
      if (to.query.page !== from.query.page) {
        this.getUsers();
      }
    },
  },
};
</script>
